const FAQ = [{
    "title": "Можно ли приостановить обучение на курсе и пройти его позже, в более удобное время?",
    "value": "Если по каким-то причинам вам нужно прервать обучение (срочная командировка, болезнь и т. д.), вы сохраняете возможность посмотреть пропущенные видеолекции. Они будут доступны в течение всего периода обучения на курсе и еще месяц после его завершения. Однако если вы не уложитесь в срок с выполнением домашнего задания или тестовой части по итогам модуля, вас автоматически исключат из активных участников курса и из общего рейтинга. Это связано с тем, что на наших курсах используется система кросс-проверки домашних заданий – учащиеся проверяют работы друг друга. И если вы отстали от общего графика, вы не сможете выполнить домашнее задание в другое время и получить проверенный результат. ",
    "category": "Прохождение обучения"
}, {
    "title": "Можно ли одновременно записаться на несколько курсов?",
    "value": "Да. Главное, чтобы вы сами успели прослушать все лекции. ",
    "category": "Прохождение обучения"
}, {
    "title": "Будут ли новые наборы учащихся на уже законченные курсы? Что такое перезапуск курса?",
    "value": "Для тех, кто записывался на курс, все лекции доступны в течение месяца после его завершения. Через месяц после окончания курса на него можно записаться снова. Когда количество записавшихся на курс превысит 3000 человек, назначается дата перезапуска. Следите за количеством записавшихся и рекомендуйте курс друзьям и знакомым, если хотите, чтобы перезапуск состоялся быстрее. ",
    "category": "Прохождение обучения"
}, {
    "title": "Я записался на курс, когда большинство модулей уже завершено, что делать? Могу ли я завершить курс и набрать нужное количество баллов?",
    "value": "Всем студентам, которые записались на курс после старта модулей, мы даем шанс сдать тестовые задания всех предыдущих модулей. Но устанавливается ограничение – если вы не успеваете пройти активный модуль, он закрывается и более не доступен для прохождения, однако ранее завершенные модули вы сможете пройти и позднее. Данное действие не распространяется на домашние задания и проверку домашних работ других участников курса. ",
    "category": "Прохождение обучения"
}, {
    "title": "Все ли курсы у вас бесплатные?",
    "value": "Нет, ряд курсов платный, например, по углубленным бизнес-программам. Рядом с ними всегда указана цена. Если ее нет, то доступ к материалам бесплатный!\r\nТакже на некоторых бесплатных курсах предусмотрены дополнительные вебинары, персональные консультации с преподавателем и получение официального сертификата о прослушивании курса. Эти дополнительные возможности тоже платные.",
    "category": "Прохождение обучения"
}, {
    "title": "Почему курсы не доступны всегда?",
    "value": "Мы не берем денег за обучение на курсах, однако проведение каждого из них требует серьезной информационной, организационной, технической и методической подготовки. Наши студенты не просто слушают видеолекции, размещенные в сети, они изучают дополнительную литературу, проходят тестирование, выполняют домашние задания, общаются между собой онлайн. Организация этого процесса требует временных и финансовых затрат. Именно поэтому наши курсы проходят по определенному графику. Это позволяет оптимизировать расходы, наладить процесс и обеспечить высокое качество материала (ведь по итогам каждого нового запуска мы подводим итоги и делаем выводы, что необходимо изменить к лучшему). ",
    "category": "Прохождение обучения"
},
    {
        "title": "Можно ли пересматривать лекции? Будут ли доступны видеолекции после окончания обучения на курсе?",
        "value": "После завершения обучения по курсу для всех, записавшихся на курс, он будет доступен для просмотра лекций еще около месяца. Далее будет перезапуск курса и повторное обучение, в течение которого все лекции конечно же тоже будут доступны.",
        "category": "Лекции",
    }, {
        "title": "Можно ли будет скачивать лекции или переводить их в формат mp3?",
        "value": "Нет. Лекции доступны только на «Универсариуме». Мы соблюдаем законодательство об авторском праве. Некоторые лекции можно также посмотреть на нашем официальном Youtube-канале. ",
        "category": "Лекции",
    }, {
        "title": "Что делать, если скорость интернета не позволяет нормально смотреть лекции, и видео тормозит?",
        "value": "Попробуйте изменить качество видео до 360р. Специальную кнопку вы найдете в правом нижнем углу дисплея.",
        "category": "Лекции",
    }, {
        "title": "У меня возникли проблемы с просмотром видео. Что делать?",
        "value": "Оставьте отзыв в специальном окошке рядом с видео-лекцией. Мы придумаем, как решить вашу проблему. ",
        "category": "Лекции",
    }, {
        "title": "Ошибки при воспроизведении видео и/или не работает тестирование. Что делать?",
        "value": "Попробуйте просмотреть лекцию (пройти тестирование) в другом обозревателе и/или очистите кэш обозревателя.",
        "category": "Лекции",
    }, {
        "title": "Если я пропустил видеолекцию или просто не стал досматривать ее до конца, это отразится на оценке?",
        "value": "Нет, просмотр лекции не является критерием оценки.",
        "category": "Лекции",
    },
    {
        "title": "Тесты к модулям можно проходить только один раз?",
        "value": "Вы можете пересдать их несколько раз. Кроме того, вы можете прослушать курс заново.",
        "category": "Тесты и задания",
    }, {
        "title": "Почему после окончания тестирования нельзя посмотреть, на какие вопросы были даны неверные ответы?",
        "value": "Вы сможете посмотреть, где допустили ошибки, только после того, как исчерпаете все пересдачи или когда модуль будет закончен. В противном случае вводить систему пересдач было бы бессмысленно.",
        "category": "Тесты и задания",
    }, {
        "title": "Как быстро проверят мою домашнюю работу?",
        "value": "Проверка домашнего задания будет идти в течение всего следующего модуля. Ваша домашняя работа будет разослана 5 другим участникам курса, которые тоже уже сдали свои задания. Они выставят вам оценки и напишут свои рецензии. Мы ввели такую систему, потому что количество участников отдельных курсов достигает десятков тысяч человек, и преподаватели просто физически не могут проверить столько работ.  Если вы будете не согласны с результатами, оценки можно оспорить.",
        "category": "Тесты и задания",
    }, {
        "title": "Ошибки при воспроизведении видео и/или не работает тестирование. Что делать?",
        "value": "Попробуйте просмотреть лекцию (пройти тестирование) в другом обозревателе и/или очистите кэш обозревателя.",
        "category": "Тесты и задания",
    }, {
        "title": "Я пропустил(а) вопросы после лекции, как быть?",
        "value": "Не стоит переживать в таком случае, вопросы, пропущенные после лекций, появятся в итоговом тесте модуля.",
        "category": "Тесты и задания",
    }, {
        "title": "Я ответил(а) на все вопросы, однако система не засчитала мне один или более ответ. Что делать?",
        "value": "Для предупреждения подобных ситуаций, во время прохождения теста появляется счетчик времени и отвеченных вопросов, номера вопросов, на которые Вы дали ответ, окрашиваются на счетчике в синий цвет, тогда как номера пропущенных вопросов остаются серыми.",
        "category": "Тесты и задания",
    }, {
        "title": "Мою домашнюю работу проверили только 2 человека. Это плохо?",
        "value": "Это нормально. Так бывает, что не все участники курса хотят участвовать в проверке работ. Если вашу работу проверили всего два человека, вы получите свою оценку на основе их решений.",
        "category": "Тесты и задания",
    }, {
        "title": "Что делать, если я уверен в правильности своих ответов в тесте, но система ставит мне незаслуженно низкий балл?",
        "value": "Напишите на mail@universarium.org, изложите свою проблему. Если система неправильно работает, вам поправят оценку.",
        "category": "Тесты и задания",
    }, {
        "title": "Что делать, если я прикрепил не тот файл при отправке домашнего задания?",
        "value": "Если вы случайно отправили не тот файл или не прикрепили его вовсе, отменить данное действие нельзя. Необходимо дождаться результатов проверки домашнего задания, после чего изложить проблему в графе «обжаловать оценку» и прикрепить задание еще раз. ",
        "category": "Тесты и задания",
    },
    {
        "title": "Получу ли я какое-то подтверждение прохождения курса?",
        "value": "После успешного прохождения курса вы получите сертификат от «Универсариума». Для его получения необходимо набрать количество баллов, соответствующее оценке «удовлетворительно». В среднем это 60-70% от максимально возможного балла, но точная цифра зависит от курса.",
        "category": "Результаты обучения",
    }, {
        "title": "В каком виде выдается сертификат и где я его могу найти? ",
        "value": "Сертификат выдается в электронном виде. При желании вы можете распечатать его самостоятельно. PDF-версия сертификата доступна в вашем личном кабинете (вкладка «Мои сертификаты – Сгенерировать – PDF-версия»). ",
        "category": "Результаты обучения",
    }, {
        "title": "Является ли сертификат от «Универсариума» подтверждением повышения квалификации?",
        "value": "Нет, наш сертификат не является свидетельством о повышении квалификации. Однако те курсы, в которых предусмотрено изучение дополнительных материалов за отдельную плату, гарантируют получение официального сертификата или удостоверения от вуза/ бизнес-тренера/ тренинговой компании (при условии успешной сдачи итогового теста). ",
        "category": "Результаты обучения",
    }, {
        "title": "Есть ли какие-либо особенности получения платного сертификата?",
        "value": "Чтобы получить платный сертификат, необходимо соблюсти все условия его получения. Например, сертификат о повышении квалификации предполагает, что у вас есть высшее или среднее образование. Условия получения платного сертификата изложены в окне оплаты на странице курса. Прежде чем производить оплату, убедитесь, что вы соответствуете всем требованиям. После оплаты проверьте, верно ли указаны данные в вашем профиле: ваши настоящие ФИО и адрес, на который будет отправлен сертификат.",
        "category": "Результаты обучения",
    }, {
        "title": "Сколько занимает получение платного сертификата?",
        "value": "В среднем процедура получения платного сертификата занимает 3-4 месяца. Этот срок связан с подсчетом и обработкой результатов обучения, сбором и передачей данных в вуз, процедурой зачисления/отчисления, оформлением и печатью сертификатов. Сюда же входит время доставки сертификатов «Почтой России».",
        "category": "Результаты обучения",
    },
    {
        "title": "Я случайно создал лишнюю учетную запись, что делать?",
        "value": "Для удаления учетной записи, пожалуйста, напишите письмо на mail@universarium.org по возможности указав причину удаления.",
        "category": "Остальное",
    }, {
        "title": "Я создал(а) аккаунт, а позже авторизовался(-лась) через социальную сеть, куда пропали курсы, на которые я записался (-лась)?",
        "value": "Авторизовавшись через социальную сеть Вы фактически создали еще один аккаунт, курсы на которые Вы записались под первой учетной записью и Ваш прогресс по ним доступны при авторизации через учетную запись Универсариум.",
        "category": "Остальное",
    }, {
        "title": "Где можно написать свои замечания о работе «Универсариума»?",
        "value": "Все отзывы и предложения добавляйте сюда: http://universarium.userecho.com/  Рядом с Вашей записью появится специальное окошко, в котором будет указано, на какой стадии рассмотрения она находится.",
        "category": "Остальное",
    }, {
        "title": "Где можно задавать вопросы преподавателям и есть ли форум для студентов по обмену вопросами или консультаций?",
        "value": "Оставлять комментарии, задавать вопросы и общаться с другими учащимися можно на странице модуля. Для этого внизу, под видеолекцией, есть специальное окошко.",
        "category": "Остальное",
    }, {
        "title": "Почему мне не приходят на почту уведомления о старте курсов?",
        "value": "Чтобы получать нашу рассылку, необходимо поставить соответствующие галочки в графе «Управление почтовыми уведомлениями» в вашем личном кабинете.",
        "category": "Остальное",
    }
]

export default FAQ;
<template>
  <div class="wrapper">
    <head-component />
    <h1 class="mt-5 mb-5">Часто задаваемые вопросы</h1>
    <VueFaqAccordion
        :items="myItems"
    />
  </div>
</template>

<script>
import VueFaqAccordion from 'vue-faq-accordion'
import HeadComponent from "../../components/HeadComponent";
import FAQ from "./faq";
export default {
  name: "FAQ",
  components: {
    HeadComponent,
    VueFaqAccordion
  },
  data () {
    return {
      myItems: FAQ
    }
  }
}
</script>

<style scoped>

</style>